import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { useWatch } from '@optii-solutions/ui-library';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
  initGQLData as initData,
  Session,
  ADD_JOB,
  CREATE_REPEATING_JOB,
  FIND_ALL_ASSETS,
  GET_DEPARTMENTS_LIST,
  GET_EMPLOYEE_LIST,
  GET_JOB_BY_ID,
  GET_JOB_ITEMS_LIST,
  GET_ROLES_LIST,
  GET_REPEATING_JOB_SUMMARIES,
  UPDATE_JOB,
  UPDATE_JOB_META_DATA,
  GET_ASSET_BY_ID,
  UPDATE_CHECKLIST_TASK,
  GET_QUEUED_JOB_BY_ID,
  GET_REPEATING_JOB_TEMPLATE,
  GET_JOBS_SETTINGS,
  GA_EVENTS,
  UPDATE_REPEATING_JOB,
  GET_ROOMS_BY_ID,
} from '@optii/shared';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { TUploadHandleFile } from '@optii/shared/types';
import {
  useAccess,
  useMinimalLocations,
  useProductAccess,
  usePropertyTime,
} from '@optii/shared/index';
import { PERMISSIONS } from '@optii/shared/constants/permissions';
import { PRODUCT_ACCESS } from '@optii/shared/constants/productAccess';
import { TJobDetailsContextJob } from '@optii/shared/contexts/JobDetailsContext';
// @ts-ignore
import { getAssetListWithNotes } from '@optii/topcat-client/components/Jobs/Shared/LocationAssetTypeSelect';
// @ts-ignore
import { sortByName } from '@optii/topcat-client/utils/sort';
// @ts-ignore
import { CHECKLIST_TASK_TYPE_CONFIG } from '@optii/topcat-client/checklists/constants';
// @ts-ignore
import { LIST_FETCH_POLICIES } from '@optii/topcat-client/utils/constants';

import {
  PatchDataInput,
  useAdhocJobPatchMutation,
} from '@optii/jobs/api/patchJob';
import { Job } from '@optii/jobs/api/jobs';
import type {
  TFormOptions,
  TJobAssetTypeLocationOption,
  TJobAssigneeOption,
  TJobData,
  TJobDataAsset,
  TJobDataItem,
  TJobDataLocation,
  TJobDeparmentOption,
  TJobInput,
  TJobInputAssignee,
  TJobInputDepartment,
  TJobInputLocations,
  TJobInputRole,
  TJobRoleOption,
  TRepeatingJobInput,
  TRepeatingJobInputAssignee,
  TRepeatingJobInputLocations,
  TRepeatingJobTemplate,
  TEditJobData,
  TEditJobInput,
  TJobFormMode,
  TEditRepeatingJobInput,
  TJobNonrepeatingChecklist,
} from './JobForm.types';
import {
  capitalize,
  getActionTypeOptions,
  getFormattedJobDeparment,
  getFormattedJobRole,
  getHKActionTypeOptions,
  getItems,
  getJobLocations,
  getJobTypes,
  getRepeatingJobCycle,
  getRepeatingJobLocations,
  getAdvancedStatusFromValue,
  getEditJobAssignee,
  getEditJobLocations,
  getStatusFromValue,
  getDecodeDailyCadenceFromText,
  getDecodeWeeklyCadenceFromText,
  getDecodeMonthlyCadenceFromText,
  getDecodeYearlyCadenceFromText,
  getEditRepeatingJobLocations,
  getRoomStatus,
} from './JobForm.helpers';
import {
  AUTO_ASSIGNEE,
  AUTO_ASSIGNEE_ID,
  DAYS_OF_WEEK_OPTIONS,
  JOB_ADVANCED_STATUSES,
  JOB_PRIORITY_LEVELS,
  JOB_REPEAT,
  JOB_REPEAT_STATUS,
  JOB_STATUSES,
  PRIORITY_LEVEL_OPTIONS,
  REFETCH_SUMMARY_VARIABLES,
  REPEAT_HOUR_OPTIONS,
  REPEAT_MONTH_OPTIONS,
  REPEAT_OPTIONS,
  REPEAT_YEAR_OPTIONS,
  STATUSES_OPTIONS,
  TIMES_PER_DAY_OPTIONS,
} from './JobForm.constants';
import { CustomStyles } from './JobForm.elements';
import {
  TFormatUserDisplayName,
  formatUserDisplayName,
} from '../../formatters/user';

type THandleAddNote = {
  id: string;
  setNotes: (a: string) => void;
  notes: string;
};

type THandleAddTemplateNote = {
  id?: string;
  setNotes: (a: string) => void;
  notes: string;
};

type TUseJobForm = {
  form: FormInstance;
  onClose?: (id?: string) => void;
  mode?: TJobFormMode;
  propertyShard?: string;
};

const generatePatchInput = (
  originalJob: Job,
  newJobPayload: TEditJobInput,
): PatchDataInput[] => {
  const data: PatchDataInput[] = [];
  if (
    originalJob.durationMin === null &&
    Number(newJobPayload.durationMin) >= 0
  ) {
    data.push({
      value: newJobPayload.durationMin,
      op: 'add',
      path: '/durationMin',
    });
  } else if (
    Number(originalJob.durationMin) >= 0 &&
    Number(newJobPayload.durationMin) >= 0
  ) {
    data.push({
      value: newJobPayload.durationMin,
      op: 'replace',
      path: '/durationMin',
    });
  }

  if (
    originalJob.creditValue === null &&
    Number(newJobPayload.creditValue) >= 0
  ) {
    data.push({
      value: newJobPayload.creditValue,
      op: 'add',
      path: '/creditValue',
    });
  } else if (
    Number(originalJob.creditValue) >= 0 &&
    Number(newJobPayload.creditValue) >= 0
  ) {
    data.push({
      value: newJobPayload.creditValue,
      op: 'replace',
      path: '/creditValue',
    });
  }

  return data;
};

export function useJobForm(props: TUseJobForm) {
  const { form, onClose, mode, propertyShard } = props;
  const [submitted, toggleSubmitted] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] =
    useState<boolean>(false);

  const customStyles = CustomStyles();

  const { t } = useTranslation(['common', 'jobs'], { useSuspense: false });
  const { globalSnack } = useContext(Session);

  const [query, setQuery] = useQueryParams({
    edit: StringParam,
    openJob: StringParam,
    openRepeatingJob: StringParam,
    pageSize: NumberParam,
    project: StringParam,
    projectCycle: StringParam,
  });

  const queuedJobRequest =
    (query.project && query.project !== '') ||
    (query.projectCycle && query.projectCycle !== '');

  const { can } = useAccess();
  const canAutoAssign = can(PERMISSIONS.jobs.autoassign) && !query.edit; // show autoassign option only in Add Job form

  const shouldShowAutoAssignOption = (canAutoAssign && [AUTO_ASSIGNEE]) || [];

  const { data: jobsSettingsData } = useQuery(GET_JOBS_SETTINGS, {
    context: {
      _shard: propertyShard,
    },
  });

  const { data: employeeData, loading: employeesLoading } = useQuery(
    GET_EMPLOYEE_LIST,
    {
      variables: {
        status: 'active',
      },
      context: {
        _shard: propertyShard,
      },
    },
  );

  const employees = shouldShowAutoAssignOption
    .concat(initData(employeeData))
    .sort(sortByName)
    .map((employee: TFormatUserDisplayName) => ({
      ...employee,
      id: employee.userId,
      displayName: formatUserDisplayName(employee),
    }));

  const { data: departmentsData, loading: dptsLoading } = useQuery(
    GET_DEPARTMENTS_LIST,
    {
      context: {
        _shard: propertyShard,
      },
    },
  );
  const departments = initData(departmentsData);

  const { data: rolesData, loading: rolsLoading } = useQuery(GET_ROLES_LIST, {
    context: {
      _shard: propertyShard,
    },
  });
  const roles = initData(rolesData).map((before: { name: string }) => ({
    ...before,
    displayName: before.name,
  }));

  const { data: assetsData, loading: assetsLoading } = useQuery(
    FIND_ALL_ASSETS,
    {
      context: { _instance: 'node', _shard: propertyShard },
    },
  );
  const assets = initData(assetsData);

  const locationsAssetType = getAssetListWithNotes(initData(assetsData));

  const jobItemHasAssociatedAssets =
    !assetsLoading && locationsAssetType?.length > 0;

  const typedActiveLocationsAssetType: TJobAssetTypeLocationOption[] =
    locationsAssetType;

  const { data: itemsData, loading: itemsLoading } = useQuery(
    GET_JOB_ITEMS_LIST,
    {
      context: {
        _shard: propertyShard,
      },
    },
  );
  const jobItems = initData(itemsData);

  const { locations, loading: loadingMinimalLocations } = useMinimalLocations({
    withLongDisplayName: true,
    propertyShard,
  });

  const {
    data: jobData,
    loading: jobLoading,
    refetch: jobRefetch,
  } = useQuery(GET_JOB_BY_ID, {
    variables: {
      id: query.openJob,
      startedAt:
        process.env.NODE_ENV === 'test'
          ? '2024-07-24 15:53:29.601000'
          : '2024-08-06 20:27:31.008847',
    },

    context: {
      _shard: propertyShard,
    },
    notifyOnNetworkStatusChange: true,
    skip: !query.openJob,
  });

  const {
    data: repeatingJobData,
    loading: repeatingJobTemplateLoading,
    refetch: repeatingJobRefetch,
  } = useQuery(GET_REPEATING_JOB_TEMPLATE, {
    context: { _instance: 'node', _shard: propertyShard },
    ...LIST_FETCH_POLICIES,
    variables: {
      repeatingJobId: query.openRepeatingJob,
    },

    skip: !query.openRepeatingJob,
  });

  const [
    getQueuedJobById,
    {
      data: queuedJobData,
      loading: queuedJobLoading,
      refetch: queuedJobRefetch,
    },
  ] = useLazyQuery(GET_QUEUED_JOB_BY_ID, {
    variables: {
      projectId: query.project,
      projectCycleId: query.projectCycle,
      jobCardHash: query.openJob,
    },
    notifyOnNetworkStatusChange: true,
    context: { _instance: 'node', _shard: propertyShard },
  });

  const [getRoomsData, { data: roomsData }] = useLazyQuery(GET_ROOMS_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { _instance: 'node', _shard: propertyShard },
  });

  const job = query.openRepeatingJob
    ? repeatingJobData?.job
    : jobData?.job || queuedJobData?.job;
  const audit = query.openRepeatingJob
    ? repeatingJobData?.audit
    : jobData?.audit || queuedJobData?.audit;

  const { data: assetData } = useQuery(GET_ASSET_BY_ID, {
    ...LIST_FETCH_POLICIES,
    context: { _instance: 'node', _shard: propertyShard },
    skip: !job?.metadata?.publicAttributes?.asset?.id,
  });
  const asset = assetData?.asset;

  const updateChecklistTask = useMutation(UPDATE_CHECKLIST_TASK, {
    context: { _instance: 'node', _shard: propertyShard },
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (queuedJobRequest) {
        queuedJobRefetch();

        return;
      }

      jobRefetch();
    },
    refetchQueries: [
      {
        query: GET_JOB_BY_ID,
        variables: {
          id: query.openJob,
          startedAt:
            process.env.NODE_ENV === 'test'
              ? '2024-07-24 15:53:29.601000'
              : '2024-08-06 20:27:31.008847',
        },
      },
    ],
  });

  const [addJob, { loading: addJobLoading }] = useMutation(ADD_JOB, {
    context: {
      _shard: propertyShard,
    },
  });

  const [addRepeatingJob, { loading: createRepeatingJobLoading }] = useMutation(
    CREATE_REPEATING_JOB,
    {
      context: { _instance: 'node', _shard: propertyShard },
    },
  );
  const [updateRepeatingJob, { loading: updateRepeatingJobLoading }] =
    useMutation(UPDATE_REPEATING_JOB, {
      context: { _instance: 'node', _shard: propertyShard },
    });

  const updateJobMutation = useMutation(UPDATE_JOB, {
    context: {
      _shard: propertyShard,
    },
  });
  const updateJobMetadataMutation = useMutation(UPDATE_JOB_META_DATA, {
    context: {
      _shard: propertyShard,
    },
  });

  const [updateJob, { loading: updateLoading }] = updateJobMutation;
  const [patchJob] = useAdhocJobPatchMutation({
    onError(error) {
      console.error('An error occurred when patching the Job', error);
    },
    context: {
      _shard: propertyShard,
    },
  });
  const [updateJobMetadata, { loading: updateJobMetadataLoading }] =
    updateJobMetadataMutation;

  const loading =
    dptsLoading ||
    rolsLoading ||
    itemsLoading ||
    loadingMinimalLocations ||
    assetsLoading ||
    jobLoading ||
    employeesLoading ||
    queuedJobLoading ||
    repeatingJobTemplateLoading;
  const createLoading = createRepeatingJobLoading || addJobLoading;
  const editLoading = updateJobMetadataLoading || updateRepeatingJobLoading;

  const { canProduct } = useProductAccess();
  const canHousekeepingJobs = can(PERMISSIONS.house_keeping.add_edit);
  const canProductHousekeeping = canProduct([PRODUCT_ACCESS.house_keeping]);

  const { timezone } = usePropertyTime(null);

  const watchedValues = useWatch([], form);

  const JOB_TYPES = getJobTypes({ t });
  const JOB_TYPE_OPTIONS = useMemo(
    () => [JOB_TYPES.guest, JOB_TYPES.internal, JOB_TYPES.housekeeping],
    [JOB_TYPES.guest, JOB_TYPES.housekeeping, JOB_TYPES.internal],
  );
  const TODAY_TIME = dayjs().tz(timezone);

  const actionTypeOptions = getActionTypeOptions({ t });
  const housekeepingActionTypeOptions = getHKActionTypeOptions({ t });
  const roomStatus =
    roomsData?.roomsById?.length && getRoomStatus({ roomsData });

  const ACTION_TYPE_OPTIONS = actionTypeOptions.map((item) => ({
    ...item,
    style: customStyles.Radio,
  }));

  const HOUSEKEEPING_ACTION_TYPE_OPTIONS = housekeepingActionTypeOptions.map(
    (item) => ({
      ...item,
      style: customStyles.Radio,
    }),
  );

  const filteredJobTypeOptions = JOB_TYPE_OPTIONS?.filter(
    (editJobTypeOptionItem) => {
      const hasAccessHKJobs =
        canHousekeepingJobs &&
        canProductHousekeeping &&
        editJobTypeOptionItem.value === JOB_TYPES.housekeeping.value;
      const hasAccessGuestAndInternalJobs =
        editJobTypeOptionItem.value === JOB_TYPES.guest.value ||
        editJobTypeOptionItem.value === JOB_TYPES.internal.value;

      if (hasAccessHKJobs || hasAccessGuestAndInternalJobs) {
        return true;
      }

      return false;
    },
  );

  const mappedJobTypeOptions =
    mode === 'add'
      ? filteredJobTypeOptions?.map((jobTypeOptionItem) => {
          const hasAccessHKJobs =
            canHousekeepingJobs &&
            canProductHousekeeping &&
            jobTypeOptionItem.value === JOB_TYPES.housekeeping.value;
          const hasAccessGuestAndInternalJobs =
            jobTypeOptionItem.value === JOB_TYPES.guest.value ||
            jobTypeOptionItem.value === JOB_TYPES.internal.value;

          if (hasAccessHKJobs || hasAccessGuestAndInternalJobs) {
            return {
              ...jobTypeOptionItem,
              disabled: false,
            };
          }

          return {
            ...jobTypeOptionItem,
            disabled: true,
          };
        })
      : filteredJobTypeOptions?.map((editJobTypeOptionItem) => {
          const isCurrentHousekeepingJobType = !!(
            job && job.type === JOB_TYPES.housekeeping.value
          );
          const isCurrentGuestOrInternalJobType = !!(
            job &&
            (job.type === JOB_TYPES.guest.value ||
              job.type === JOB_TYPES.internal.value)
          );
          const shouldDisableHousekeeping =
            isCurrentHousekeepingJobType &&
            editJobTypeOptionItem.value === JOB_TYPES.housekeeping.value;
          const shouldDisableGuestAndInternal =
            isCurrentGuestOrInternalJobType &&
            (editJobTypeOptionItem.value === JOB_TYPES.guest.value ||
              editJobTypeOptionItem.value === JOB_TYPES.internal.value);

          if (shouldDisableHousekeeping || shouldDisableGuestAndInternal) {
            return {
              ...editJobTypeOptionItem,
              disabled: false,
            };
          }

          return {
            ...editJobTypeOptionItem,
            disabled: true,
          };
        });

  const DEPARTMENT_OPTIONS = departments.map(
    (departmentItem: TJobDeparmentOption) => {
      const { id, displayName, departmentCode } = departmentItem;

      const extra = {
        id,
        displayName,
        departmentCode,
      };

      return {
        label: displayName,
        value: id,
        extra,
      };
    },
  );

  const ROLE_OPTIONS = roles.map((roleItem: TJobRoleOption) => {
    const { id, name, description } = roleItem;

    const extra = {
      id,
      name,
      description,
    };

    return {
      label: name,
      value: id,
      extra,
    };
  });

  const JOB_ITEM_OPTIONS = jobItems.map(
    (jobItem: { id: string | number; displayName: string }) => {
      const { id, displayName } = jobItem;

      const isAsset = assets?.find(
        (assetItem: {
          assetType: {
            jobItem: {
              id: string;
            };
          };
        }) => assetItem.assetType.jobItem.id === id,
      );

      return {
        label: displayName,
        value: id,
        isAsset,
      };
    },
  );

  const LOCATION_OPTIONS = locations;

  const ASSIGNEE_OPTIONS =
    watchedValues &&
    employees?.map((employeeItem: TJobAssigneeOption) => {
      const hasNonNotStartedStatuses =
        watchedValues.status === JOB_STATUSES.completed.value ||
        watchedValues.status === JOB_STATUSES.cancelled.value ||
        watchedValues.status === JOB_STATUSES.inProgress.value ||
        watchedValues.status === JOB_STATUSES.onHold.value;

      const isCurrentEmployeeItemIdAutoAssigneeID =
        String(employeeItem?.id) === String(AUTO_ASSIGNEE_ID);
      const isWatchedValuesAssigneeAutoAssigneeID =
        String(watchedValues.assignee) === String(AUTO_ASSIGNEE_ID) ||
        String(watchedValues.assignee?.id) === String(AUTO_ASSIGNEE_ID);

      if (hasNonNotStartedStatuses && isCurrentEmployeeItemIdAutoAssigneeID) {
        if (isWatchedValuesAssigneeAutoAssigneeID) {
          form.setFieldValue('assignee', null);
        }

        return null;
      }

      const {
        id,
        displayName,
        userFirstName: firstName,
        userLastName: lastName,
        userName,
      } = employeeItem;

      const extra = {
        id,
        userName,
        displayName,
        firstName,
        lastName,
      };

      return {
        label: displayName,
        value: String(id),
        extra,
      };
    });

  const FORM_OPTIONS: TFormOptions = useMemo(
    () => ({
      jobType: mappedJobTypeOptions,
      actionType: actionTypeOptions.map((actionTypeOption) => ({
        ...actionTypeOption,
        active: !!(form.getFieldValue('actionType') === actionTypeOption.value),
      })),
      housekeepingActionType: housekeepingActionTypeOptions.map(
        (actionTypeOption) => ({
          ...actionTypeOption,
          active: !!(
            form.getFieldValue('actionType') === actionTypeOption.value
          ),
        }),
      ),
      assetTypeLocations: typedActiveLocationsAssetType.map(
        (activeLocationsAssetTypeItem: TJobAssetTypeLocationOption) => {
          const { id, location, assetType, notes } =
            activeLocationsAssetTypeItem;
          const extra = {
            id,
            locationId: location?.id,
            locationTitle: location?.title,
            jobItem: assetType.jobItem.displayName,
          };

          return {
            label: `${location.longDisplayName} ${assetType.displayName} ${
              notes || ''
            }`,
            value: id,
            extra,
          };
        },
      ),
      items: JOB_ITEM_OPTIONS,
      location: LOCATION_OPTIONS,
      statuses: STATUSES_OPTIONS,
      priorityLevel: PRIORITY_LEVEL_OPTIONS,
      repeat: REPEAT_OPTIONS,
      daysOfWeek: DAYS_OF_WEEK_OPTIONS,
      timesPerDay: TIMES_PER_DAY_OPTIONS,
      repeatHour: REPEAT_HOUR_OPTIONS,
      repeatMonth: REPEAT_MONTH_OPTIONS,
      repeatYear: REPEAT_YEAR_OPTIONS,
      assignee: ASSIGNEE_OPTIONS,
      department: DEPARTMENT_OPTIONS,
      role: ROLE_OPTIONS,
    }),
    [
      ASSIGNEE_OPTIONS,
      DEPARTMENT_OPTIONS,
      JOB_ITEM_OPTIONS,
      LOCATION_OPTIONS,
      ROLE_OPTIONS,
      actionTypeOptions,
      mappedJobTypeOptions,
      form,
      housekeepingActionTypeOptions,
      typedActiveLocationsAssetType,
    ],
  );

  function needToCompleteTasks(_job: TJobDetailsContextJob) {
    const hasChecklist = _job.checklists?.length;

    if (!hasChecklist) return false;

    const completableTasks =
      _job.checklists &&
      _job.checklists[0].checklistTasks.filter(
        (item: { taskType: string }) =>
          CHECKLIST_TASK_TYPE_CONFIG[
            item.taskType as keyof typeof CHECKLIST_TASK_TYPE_CONFIG
          ]?.fulfillment?.fulfillable,
      );

    if (completableTasks?.length === 0) return false;

    const requiredTasks = completableTasks.filter(
      (completableTasksItem: { required: boolean }) =>
        completableTasksItem.required,
    );

    if (requiredTasks?.length === 0) return false;

    const completedRequiredTasks = completableTasks.filter(
      (completableTasksItem: { taskType: string; required: boolean }) =>
        completableTasksItem.required &&
        CHECKLIST_TASK_TYPE_CONFIG[
          completableTasksItem.taskType as keyof typeof CHECKLIST_TASK_TYPE_CONFIG
        ]?.fulfillment?.isFulfilled(completableTasksItem),
    );

    const requiredTasksAreCompleted =
      requiredTasks?.length === completedRequiredTasks?.length;

    return !requiredTasksAreCompleted;
  }

  const formatRegularFields = useCallback(
    (values: TJobData) => {
      const { items, department, role } = values;
      const valuesAsset: TJobDataAsset[] = values?.asset || [];
      const valuesLocation: string[] = values?.location || [];
      const isAssetTypeLocation = !!values.asset;
      const checklistTemplates: TJobNonrepeatingChecklist = values.checklist
        ?.length
        ? [values?.checklist].map((value: string, index: number) => ({
            order: index,
            id: value,
          }))
        : [];
      const attachments =
        values.attachments?.map(
          (attachmentItem: TUploadHandleFile | string) => {
            if (typeof attachmentItem !== 'string' && attachmentItem.uid) {
              return attachmentItem.uid;
            }
            return attachmentItem;
          },
        ) || [];

      const currentAssetLocations: TJobDataAsset[] = isAssetTypeLocation
        ? valuesAsset
        : [];
      const currentLocations: TJobDataLocation[] = !isAssetTypeLocation
        ? valuesLocation?.map(
            (item) => ({ id: String(item) }) as TJobDataLocation,
          )
        : [];

      const selectedLocations = getJobLocations({
        currentLocations,
        currentAssetLocations,
        locations,
        isAssetTypeLocation,
      });

      const currentDeparment =
        typeof department === 'string' ? department : undefined;

      const selectedDepartment = getFormattedJobDeparment({
        currentDeparment,
        departments,
        departmentHasNotBeenChanged: !!(typeof department === 'string'),
        department: department as TJobInputDepartment,
      });

      const currentRole = typeof role === 'string' ? role : undefined;

      const selectedRole = getFormattedJobRole({
        currentRole,
        roles,
        roleHasNotBeenChanged: !!(typeof role === 'string'),
        role: role as TJobInputRole,
      });

      const isAutoAssignee =
        typeof values?.assignee === 'string' &&
        values?.assignee === String(AUTO_ASSIGNEE_ID);
      const selectedAssignee: TJobInputAssignee =
        typeof values.assignee === 'object'
          ? {
              id: values.assignee?.id || '',
              userName: values.assignee?.userName || '',
              firstName: values.assignee?.firstName || '',
              lastName: values.assignee?.lastName || '',
            }
          : {
              id: '',
              userName: '',
              firstName: '',
              lastName: '',
            };

      const assignee = isAutoAssignee
        ? {
            id: String(AUTO_ASSIGNEE.id),
            userName: AUTO_ASSIGNEE.userName,
            firstName: AUTO_ASSIGNEE.firstName,
            lastName: AUTO_ASSIGNEE.lastName,
          }
        : selectedAssignee;

      const doBy =
        (values.dueTime &&
          values.dueDate &&
          values.dueDate
            .set('hour', values.dueTime.hour())
            .set('minute', values.dueTime.minute())
            .set('second', values.dueTime.second())
            .utc()
            .unix()) ||
        TODAY_TIME.utc().unix();

      const scheduleStartTimeAt =
        (values.scheduleStartTimeAt &&
          values.scheduleStartTimeAt
            .set('hour', values.scheduleStartTimeAt.hour())
            .set('minute', values.scheduleStartTimeAt.minute())
            .set('second', values.scheduleStartTimeAt.second())
            .utc()
            .unix()) ||
        TODAY_TIME.utc().unix();

      const formData: TJobInput = {
        type: values.jobType,
        action: values.actionType,
        items: ((items?.length &&
          items?.map((item) => {
            const { id, amount } = item;
            const jobItem: TJobDataItem = jobItems.find(
              (jobitem: { id: string }) => jobitem.id === id,
            ) || { id: '', amount: 0 };
            const name = jobItem?.displayName
              ? jobItem.displayName
              : (id as string);

            return {
              name,
              amount,
            };
          })) ||
          []) as TJobDataItem[],
        priority: values.priority || 'highest',
        doBy,
        scheduleStartTimeAt,
        checklistTemplates,
        note: values.notes,
        predictiveDueTime: dayjs(values.predictiveDueTime).utc().unix(),
        locations: selectedLocations as TJobInputLocations[],
        department: selectedDepartment,
        role: selectedRole,
        assignee,
        status: values.status,
        attachments,
      };

      if (values.jobType === JOB_TYPES.housekeeping.value) {
        Object.assign(formData, {
          ...formData,
          priority: 'medium',
        });
      }

      if (jobItemHasAssociatedAssets && values.asset?.length) {
        const [singleAsset] = valuesAsset;
        const { id } = singleAsset;
        const publicAttributes = { asset: { id } };

        formData.metadata = {
          publicAttributes,
        };
      }

      return formData;
    },
    [
      locations,
      departments,
      roles,
      TODAY_TIME,
      JOB_TYPES.housekeeping.value,
      jobItemHasAssociatedAssets,
      jobItems,
    ],
  );

  const formatHousekeepingFields = useCallback(
    (values: TJobInput) => {
      const formData = values;

      const timeWindowStart = watchedValues.timeWindowStart
        ? dayjs(watchedValues.timeWindowStart).utc().unix()
        : null;

      const timeWindowEnd = watchedValues.timeWindowEnd
        ? dayjs(watchedValues.timeWindowEnd).utc().unix()
        : null;

      const checklistTemplates =
        (typeof watchedValues.checklist === 'object' &&
          watchedValues.checklist?.length &&
          watchedValues.checklist?.map(
            (checklistItem: string, index: number) => ({
              order: index,
              id: checklistItem,
            }),
          )) ||
        null;

      Object.assign(formData, {
        ...formData,
        creditValue: watchedValues.creditValue,
        durationMin: watchedValues.durationMinutes,
        isRushed: watchedValues.rush,
      });

      if (timeWindowStart) {
        Object.assign(formData, {
          ...formData,
          timeWindowStart,
        });
      }

      if (timeWindowEnd) {
        Object.assign(formData, {
          ...formData,
          timeWindowEnd,
        });
      }

      if (checklistTemplates) {
        Object.assign(formData, {
          ...formData,
          checklistTemplates,
        });
      }

      return formData;
    },
    [watchedValues],
  );

  const formatRegularRepeatingJobFields = useCallback(
    (_values: TJobData & TEditJobData) => {
      const { items } = _values;

      const valuesAsset: TJobDataAsset[] = _values?.asset || [];
      const valuesLocation: string[] = _values?.location || [];
      const isAssetTypeLocation = !!_values.asset;

      const currentAssetLocations: TJobDataAsset[] = isAssetTypeLocation
        ? valuesAsset
        : [];
      const currentLocations: string[] = !isAssetTypeLocation
        ? valuesLocation
        : [];

      const selectedLocations = getRepeatingJobLocations({
        currentLocations,
        currentAssetLocations,
        locations,
        isAssetTypeLocation,
      });

      const { department } = _values;

      const currentDeparment =
        typeof department === 'string' ? department : undefined;

      const selectedDepartment = getFormattedJobDeparment({
        currentDeparment,
        departments,
        departmentHasNotBeenChanged: !!(typeof department === 'string'),
        department: department as TJobInputDepartment,
      });

      const valuesRoles = _values.role?.id
        ? {
            id: _values.role?.id || '',
          }
        : undefined;

      const isAutoAssignee =
        typeof _values?.assignee === 'string' &&
        _values?.assignee === String(AUTO_ASSIGNEE_ID);
      const selectedAssignee: TRepeatingJobInputAssignee =
        typeof _values.assignee === 'object'
          ? {
              employeeId: String(_values.assignee?.id),
            }
          : undefined;

      const assignee = isAutoAssignee
        ? {
            employeeId: String(AUTO_ASSIGNEE.id),
          }
        : selectedAssignee;

      const seletedStatus =
        _values?.status === JOB_STATUSES.notStarted.value
          ? JOB_ADVANCED_STATUSES.new?.value
          : _values?.status;

      const cycle = getRepeatingJobCycle({ values: _values });

      const notes = _values.notes
        ? [
            {
              note: _values.notes,
            },
          ]
        : undefined;

      const checklistTemplates =
        typeof _values.checklist === 'string'
          ? [
              {
                id: _values.checklist,
              },
            ]
          : undefined;

      const time = {
        daily: _values.dailyDueTime,
        weekly: _values.weeklyDueTime,
        monthly: _values.monthlyDueTime,
        yearly: _values.yearlyDueTime,
      };
      const timeKey = _values.repeat as keyof typeof time;
      const selectedTimeKey = time[timeKey];
      const currentDueByTime = selectedTimeKey || _values.firstDueTime;

      let dueByTime;
      let scheduleStartTimeAt;

      if (dayjs().tz(timezone).utcOffset() === 0) {
        dueByTime = currentDueByTime
          ? dayjs(currentDueByTime).format('HH:mm:ss')
          : undefined;
        scheduleStartTimeAt = _values.scheduleStartTimeAt
          ? dayjs(_values.scheduleStartTimeAt).format('HH:mm:ss')
          : undefined;
      } else {
        dueByTime = currentDueByTime
          ? dayjs(currentDueByTime).tz(timezone, true).utc().format('HH:mm:ss')
          : undefined;
        scheduleStartTimeAt = _values.scheduleStartTimeAt
          ? dayjs(_values.scheduleStartTimeAt)
              .tz(timezone, true)
              .utc()
              .format('HH:mm:ss')
          : undefined;
      }

      const jobTemplate: TRepeatingJobTemplate = {
        type: _values.jobType,
        action: _values.actionType,
        items: (items?.length &&
          items?.map((item) => {
            const { id, amount } = item;
            const jobItem: TJobDataItem = jobItems.find(
              (_jobItem: TJobDataItem) => _jobItem.id === id,
            ) || { id: '', amount: 0 };
            const name = jobItem?.displayName
              ? jobItem.displayName
              : (id as string);

            return {
              name,
              amount,
            };
          })) as TJobDataItem[],
        priority: _values.priority,
        department: [
          {
            id: selectedDepartment.id,
          },
        ],
        roles: valuesRoles,
        assignee,
        status: seletedStatus,
        dueByTime,
        scheduleStartTimeAt,
        location: selectedLocations as TRepeatingJobInputLocations[],
        attachments:
          _values.attachments?.map(
            (attachmentItem: TUploadHandleFile | string) => {
              if (typeof attachmentItem !== 'string' && attachmentItem.uid) {
                return attachmentItem.uid;
              }
              return attachmentItem;
            },
          ) || [],
        notes,
        checklistTemplates,
      };

      if (jobItemHasAssociatedAssets && _values.asset?.length) {
        const [singleAsset] = valuesAsset;
        const { id } = singleAsset;
        const publicAttributes = { asset: { id } };

        jobTemplate.metadata = {
          publicAttributes,
        };
      }

      const formData: TRepeatingJobInput = {
        status: JOB_REPEAT_STATUS,
        startDate: dayjs(_values.startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: _values.endDate
          ? dayjs(_values.endDate).format('YYYY-MM-DDTHH:mm:ss')
          : null,
        cycle,
        jobTemplate,
      };

      return formData;
    },
    [locations, departments, timezone, jobItemHasAssociatedAssets, jobItems],
  );

  const formatFields = useCallback(() => {
    const isRepeatingJob =
      watchedValues?.repeat && watchedValues?.repeat !== JOB_REPEAT.no.value;
    const isHoukeepingJob =
      watchedValues?.jobType === JOB_TYPES.housekeeping.value;

    if (isRepeatingJob) {
      return formatRegularRepeatingJobFields(watchedValues);
    }

    const formattedFields = formatRegularFields(watchedValues);

    if (isHoukeepingJob) {
      return formatHousekeepingFields(formattedFields);
    }

    return formattedFields;
  }, [
    watchedValues,
    JOB_TYPES.housekeeping.value,
    formatRegularFields,
    formatRegularRepeatingJobFields,
    formatHousekeepingFields,
  ]);

  const formatEditHousekeepingFields = useCallback(
    (values: TEditJobInput) => {
      const formData = values;

      const formatTimeWindowStart = watchedValues.timeWindowStart
        ? dayjs(watchedValues.timeWindowStart).utc().unix()
        : null;

      const formatTimeWindowEnd = watchedValues.timeWindowEnd
        ? dayjs(watchedValues.timeWindowEnd).utc().unix()
        : null;

      const checklistTemplates =
        (typeof watchedValues.checklist === 'object' &&
          watchedValues.checklist?.length &&
          watchedValues.checklist?.map(
            (checklistItem: string, index: number) => ({
              order: index,
              id: checklistItem,
            }),
          )) ||
        null;

      Object.assign(formData, {
        ...formData,
        creditValue: watchedValues.creditValue,
        durationMin: watchedValues.durationMinutes,
        isRushed: watchedValues.rush,
      });

      if (formatTimeWindowStart) {
        Object.assign(formData, {
          ...formData,
          timeWindowStart: formatTimeWindowStart,
        });
      }

      if (formatTimeWindowEnd) {
        Object.assign(formData, {
          ...formData,
          timeWindowEnd: formatTimeWindowEnd,
        });
      }

      if (checklistTemplates) {
        Object.assign(formData, {
          ...formData,
          checklistTemplates,
        });
      }

      return formData;
    },
    [watchedValues],
  );

  const formatEditRegularFields = useCallback(
    (values: TEditJobData) => {
      if (job) {
        const formatActionType =
          !values.actionType && job.action !== values.actionType
            ? 'noAction'
            : values.actionType;

        const formatAsset: TJobDataAsset[] = values.asset || [];
        const formatLocation: string[] =
          values.location?.map((item: any) => item.id || item) || [];
        const fallbackLocations = job.locations;
        const isAssetTypeLocation = !!values.asset;
        const formatDepartment = values.department as TJobInputDepartment;
        const formatRole = values.role as TJobInputRole;
        const formatAssignee = values.assignee as TJobInputAssignee;
        const formatAttachments = values.attachments?.map(
          (attachmentItem: TUploadHandleFile | string) => {
            if (typeof attachmentItem !== 'string' && attachmentItem.uid) {
              return attachmentItem.uid;
            }
            return attachmentItem;
          },
        );

        const originalTemplate =
          (job?.checklistTemplate?.length &&
            job?.checklistTemplate?.map(
              (checklistTemplate: { id: string }) => checklistTemplate.id,
            )) ||
          [];

        const checklistTemplates: TJobNonrepeatingChecklist = values.checklist
          ?.length
          ? [values?.checklist].flat().map((value: string, index: number) => ({
              order: index,
              id: value,
            }))
          : [originalTemplate].flat().map((value: string, index: number) => ({
              order: index,
              id: value,
            }));

        const departmentHasNotBeenChanged = !!(
          values?.department && typeof values?.department === 'string'
        );
        const roleHasNotBeenChanged = !!(
          values?.role && typeof values?.role === 'string'
        );
        const assigneeHasNotBeenChanged = !!(
          values?.assignee && typeof values?.assignee === 'string'
        );

        const currentAssetLocations: TJobDataAsset[] = isAssetTypeLocation
          ? formatAsset
          : [];
        const currentLocations: string[] = !isAssetTypeLocation
          ? formatLocation
          : [];

        const currentDeparment = departmentHasNotBeenChanged
          ? String(formatDepartment)
          : undefined;
        const currentRole = roleHasNotBeenChanged
          ? String(formatRole)
          : undefined;
        const currentAssignee = assigneeHasNotBeenChanged
          ? String(formatAssignee)
          : undefined;

        const selectedLocations = getEditJobLocations({
          currentLocations,
          currentAssetLocations,
          locations,
          isAssetTypeLocation,
          fallbackLocations,
        });

        const selectedStatus = getAdvancedStatusFromValue({
          value: values?.status || '',
        });

        const selectedDepartment = getFormattedJobDeparment({
          currentDeparment,
          departments,
          departmentHasNotBeenChanged,
          department: formatDepartment,
        });

        const selectedRole = getFormattedJobRole({
          currentRole,
          roles,
          roleHasNotBeenChanged,
          role: formatRole,
        });

        const selectedAssignee = getEditJobAssignee({
          currentAssignee,
          assignees: employees,
          assigneeHasNotBeenChanged,
          assignee: formatAssignee,
        });

        const doBy =
          values.jobType !== JOB_TYPES.housekeeping.value && values.dueTime
            ? values.dueDate
                .set('hour', values.dueTime.hour())
                .set('minute', values.dueTime.minute())
                .set('second', values.dueTime.second())
                .utc()
                .unix()
            : null;

        const scheduleStartTimeAt =
          values.jobType !== JOB_TYPES.housekeeping.value
            ? values.scheduleStartTimeAt
                ?.set('hour', values.scheduleStartTimeAt.hour())
                .set('minute', values.scheduleStartTimeAt.minute())
                .set('second', values.scheduleStartTimeAt.second())
                .utc()
                .unix()
            : null;

        const formData: TEditJobInput = {
          type: values.jobType,
          action: formatActionType,
          items: ((values?.items?.length &&
            values?.items?.map((_item: TJobDataItem) => {
              const { id, amount } = _item;
              const jobItem = jobItems.find(
                (jItem: { id: string }) => jItem.id === id,
              );
              const name = jobItem ? jobItem.displayName : (id as string);

              return {
                name,
                amount,
              };
            })) ||
            []) as TJobDataItem[],
          locations: selectedLocations as TJobInputLocations[],
          priority: values.priority,
          doBy,
          scheduleStartTimeAt,
          predictiveDueTime: dayjs(values.predictiveDueTime).utc().unix(),
          department: selectedDepartment,
          role: selectedRole,
          assignee: selectedAssignee,
          status: selectedStatus,
          attachments: formatAttachments,
          checklistTemplates,
        };

        if (!selectedRole && job.role) {
          formData.role = {
            id: '0',
            name: 'DELETE',
            description: 'DELETE',
          };
        }

        if (!selectedAssignee && job.assignee) {
          formData.assignee = {
            id: '0',
            userName: 'DELETE',
            firstName: 'DELETE',
            lastName: 'DELETE',
          };
        }

        if (jobItemHasAssociatedAssets && values.asset?.length) {
          const { asset: valuesAsset } = values;
          const [singleAsset] = valuesAsset;
          const { id } = singleAsset;
          const publicAttributes = { asset: { id: id || valuesAsset } };
          formData.metadata = {
            publicAttributes,
          };
        }

        return formData;
      }

      return {} as TEditJobData;
    },
    [
      job,
      locations,
      departments,
      roles,
      employees,
      JOB_TYPES.housekeeping.value,
      jobItemHasAssociatedAssets,
      jobItems,
    ],
  );

  const formatEditFields = useCallback(() => {
    if (job) {
      const isHoukeepingJob =
        watchedValues?.jobType === JOB_TYPES.housekeeping.value;

      const formattedFields = formatEditRegularFields(
        watchedValues,
      ) as TEditJobInput;

      if (isHoukeepingJob) {
        return formatEditHousekeepingFields(formattedFields);
      }

      return formattedFields;
    }

    return {} as TEditJobInput;
  }, [
    job,
    watchedValues,
    JOB_TYPES.housekeeping.value,
    formatEditRegularFields,
    formatEditHousekeepingFields,
  ]);

  const formatEditRepeatingFields = useCallback(
    (_values: TJobData & TEditJobData) => {
      if (job) {
        const { items } = _values;

        const valuesAsset: TJobDataAsset[] = _values?.asset || [];
        const valuesLocation: string[] =
          _values?.location ||
          job?.jobTemplate?.locations?.map(
            (locationItem: { id: string }) => locationItem.id,
          ) ||
          [];
        const isAssetTypeLocation = !!_values.asset;

        const currentAssetLocations: TJobDataAsset[] = isAssetTypeLocation
          ? valuesAsset
          : [];
        const currentLocations: string[] = valuesLocation;

        const selectedLocations = getEditRepeatingJobLocations({
          currentLocations,
          currentAssetLocations,
          locations,
          isAssetTypeLocation,
        });

        const { department } = _values;

        const currentDeparment =
          typeof department === 'string' ? department : undefined;

        const selectedDepartment = getFormattedJobDeparment({
          currentDeparment,
          departments,
          departmentHasNotBeenChanged: !!(typeof department === 'string'),
          department: department as TJobInputDepartment,
        });

        const valuesRoles =
          _values.role?.id || typeof _values.role === 'string'
            ? {
                id: _values.role?.id || String(_values.role) || '',
              }
            : undefined;

        const isAutoAssignee =
          typeof _values?.assignee === 'string' &&
          _values?.assignee === String(AUTO_ASSIGNEE_ID);
        const previousAssignee =
          typeof _values.assignee === 'string'
            ? {
                employeeId: _values.assignee,
              }
            : null;
        const selectedAssignee: TRepeatingJobInputAssignee | null =
          typeof _values.assignee === 'object' && _values.assignee?.id
            ? {
                employeeId: String(_values.assignee?.id),
              }
            : previousAssignee;

        const assignee = isAutoAssignee
          ? {
              employeeId: String(AUTO_ASSIGNEE.id),
            }
          : selectedAssignee;

        const seletedStatus =
          _values?.status === JOB_STATUSES.notStarted.value
            ? JOB_ADVANCED_STATUSES.new?.value
            : _values?.status;

        const cycle = getRepeatingJobCycle({ values: _values });

        Object.assign(cycle, {
          ...cycle,
          frequency: _values.repeat,
        });

        const notes = _values?.notes;

        const checklistTemplates =
          typeof _values.checklist === 'string'
            ? [
                {
                  id: _values.checklist,
                },
              ]
            : undefined;

        const time = {
          daily: _values.dailyDueTime,
          weekly: _values.weeklyDueTime,
          monthly: _values.monthlyDueTime,
          yearly: _values.yearlyDueTime,
        };
        const timeKey = _values.repeat as keyof typeof time;
        const selectedTimeKey = time[timeKey];
        const currentDueByTime = selectedTimeKey || _values.firstDueTime;

        let dueByTime;
        let scheduleStartTimeAt;

        if (dayjs().tz(timezone).utcOffset() === 0) {
          dueByTime = currentDueByTime
            ? dayjs(currentDueByTime).format('HH:mm:ss')
            : undefined;
          scheduleStartTimeAt = _values.scheduleStartTimeAt
            ? dayjs(_values.scheduleStartTimeAt).format('HH:mm:ss')
            : undefined;
        } else {
          dueByTime = currentDueByTime
            ? dayjs(currentDueByTime)
                .tz(timezone, true)
                .utc()
                .format('HH:mm:ss')
            : undefined;
          scheduleStartTimeAt = _values.scheduleStartTimeAt
            ? dayjs(_values.scheduleStartTimeAt)
                .tz(timezone, true)
                .utc()
                .format('HH:mm:ss')
            : undefined;
        }

        const jobTemplate: TRepeatingJobTemplate = {
          type: _values.jobType,
          action: _values.actionType,
          items: (items?.length &&
            items?.map((item) => {
              const { id, amount } = item;
              const jobItem: TJobDataItem = jobItems.find(
                (_jobItem: TJobDataItem) => _jobItem.id === id,
              ) || { id: '', amount: 0 };
              const name = jobItem?.displayName
                ? jobItem.displayName
                : (id as string);

              return {
                name,
                amount,
              };
            })) as TJobDataItem[],
          priority: _values.priority,
          department: [
            {
              id: selectedDepartment.id,
            },
          ],
          roles: valuesRoles,
          assignee,
          status: seletedStatus,
          dueByTime,
          scheduleStartTimeAt,
          location: selectedLocations.map((selectedLocation) => ({
            id: selectedLocation.id,
          })) as TRepeatingJobInputLocations[],
          attachments:
            _values.attachments?.map(
              (attachmentItem: TUploadHandleFile | string) => {
                if (typeof attachmentItem !== 'string' && attachmentItem.uid) {
                  return attachmentItem.uid;
                }
                return attachmentItem;
              },
            ) || [],
          notes,
          checklistTemplates,
        };

        if (jobItemHasAssociatedAssets && _values.asset) {
          const [singleAsset] = valuesAsset;
          const { id } = singleAsset;
          const publicAttributes = { asset: { id: id || String(valuesAsset) } };
          jobTemplate.metadata = {
            publicAttributes,
          };
        }

        const startDate = dayjs(_values.startDate)
          .tz(timezone)
          .format('YYYY-MM-DDTHH:mm:ss');
        const endDate = _values.endDate
          ? dayjs(_values.endDate).tz(timezone).format('YYYY-MM-DDTHH:mm:ss')
          : null;

        const formData: TEditRepeatingJobInput = {
          id: job.id,
          version: job.version,
          status: job.status,
          startDate,
          endDate,
          cycle,
          jobTemplate,
        };

        return formData;
      }

      return {} as TEditJobData;
    },
    [
      departments,
      job,
      jobItemHasAssociatedAssets,
      jobItems,
      locations,
      timezone,
    ],
  );

  const handleCreateSubmit = useCallback(
    async (_values: TJobData) => {
      const isHousekeepingJob =
        _values.jobType === JOB_TYPES.housekeeping.value;
      const isRepeatingJob =
        !isHousekeepingJob &&
        _values.repeat &&
        _values.repeat !== JOB_REPEAT.no.value;
      try {
        const formattedData = formatFields();

        if (isRepeatingJob) {
          await addRepeatingJob({
            variables: {
              createRepeatingJobInput: formattedData,
            },
            refetchQueries: [
              {
                query: GET_REPEATING_JOB_SUMMARIES,
                variables: REFETCH_SUMMARY_VARIABLES,
                context: { _instance: 'node' },
              },
            ],
            onCompleted({ createRepeatingJob }) {
              const { jobTemplate } = createRepeatingJob;
              const message = t(
                'jobs:Repeating job successfully added to {{action}} {{jobItem}}',
                {
                  action: jobTemplate.action
                    ? capitalize(jobTemplate.action)
                    : '',
                  jobItem: jobTemplate.items
                    .map(
                      (
                        item: { name: string; amount: number },
                        i: number,
                        array: [],
                      ) =>
                        array.length > 1
                          ? `${item.name} (${item.amount})`
                          : `${item.name}`,
                    )
                    .join(', '),
                },
              );
              globalSnack({
                message,
                timeout: 6000,
              });
              if (onClose && typeof onClose === 'function') {
                onClose();
              }
              form.resetFields();
            },
            onError(error) {
              console.error(error);
              globalSnack({
                message: t('jobs:Error adding job'),
                error: true,
                timeout: 6000,
              });
            },
          });
        } else {
          await addJob({
            variables: { input: formattedData },
            onCompleted({ addJob: _addJob }) {
              const { id, action, items, locations: addJobLocations } = _addJob;

              const selectedAction = (
                !isHousekeepingJob
                  ? ACTION_TYPE_OPTIONS
                  : HOUSEKEEPING_ACTION_TYPE_OPTIONS
              ).find((actionType) => actionType.value === action)?.label;
              const endText = t('common: has been added');

              const message = selectedAction
                ? `#${id} ${selectedAction} ${getItems(items)} ${
                    isHousekeepingJob ? addJobLocations[0].shortDisplayName : ''
                  }  ${endText}`
                : `#${id} ${getItems(items)} ${
                    addJobLocations[0].shortDisplayName
                  } ${endText}`;

              globalSnack({
                message,
                timeout: 6000,
              });

              if (onClose && typeof onClose === 'function') {
                onClose(id);
              }

              form.resetFields();
            },
          });
        }
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t(
            'assets:Something went wrong attempting to create asset type',
          ),
          error: true,
          timeout: 5000,
        });
      }
    },
    [
      JOB_TYPES.housekeeping.value,
      addRepeatingJob,
      t,
      globalSnack,
      onClose,
      form,
      formatFields,
      addJob,
      ACTION_TYPE_OPTIONS,
      HOUSEKEEPING_ACTION_TYPE_OPTIONS,
    ],
  );

  const handleEditSubmit = useCallback(
    async (_values: TEditJobData) => {
      try {
        const submitIsHousekeepingJob =
          _values.jobType === JOB_TYPES.housekeeping.value;

        if (
          job &&
          _values.status === JOB_STATUSES.completed.value &&
          needToCompleteTasks(job)
        ) {
          return globalSnack({
            message: t(
              'jobs:Required tasks must be completed in order to complete the job',
            ),
            timeout: 6000,
            error: true,
          });
        }

        const formattedJobInput = formatEditFields();

        if (formattedJobInput) {
          const { metadata, ...input } = formattedJobInput;
          const data = generatePatchInput(job, formattedJobInput);

          if (data.length) {
            await patchJob({
              variables: {
                adhocJobPatchInput: {
                  id: Number(job?.id),
                  data,
                },
              },
            });
          }

          const response = await updateJob({
            variables: { id: job?.id, input },
            update: (
              cache,
              {
                data,
              }: {
                data?: {
                  updateJobById: { id: string | number; status: string };
                };
              },
            ) => {
              if (data?.updateJobById) {
                const { updateJobById } = data;
                cache.writeFragment({
                  id: `AdhocJob:${updateJobById.id}`,
                  fragment: gql`
                    fragment UpdateAdhocJob on AdhocJob {
                      status
                    }
                  `,
                  data: {
                    status: updateJobById.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
                  },
                });
              }
            },
          });

          await updateJobMetadata({
            variables: {
              id: job?.id,
              schema: 'asset',
              input: {
                publicAttributes: {
                  id: metadata?.publicAttributes?.asset?.id ?? '',
                },
              },
            },
          });

          const {
            id,
            action,
            items: submitItems,
            parentChecklistTaskId,
            locations: locationsResponse,
          } = response.data.updateJobById;

          const currentAction = (
            !submitIsHousekeepingJob
              ? ACTION_TYPE_OPTIONS
              : HOUSEKEEPING_ACTION_TYPE_OPTIONS
          ).find((_actionType) => _actionType.value === action);
          const actionName = currentAction?.label || '';
          const displayedActionName =
            !parentChecklistTaskId && action ? actionName : '';
          const endText = t('common: has been updated');

          const message = displayedActionName
            ? `#${id} ${displayedActionName} ${getItems(submitItems)} ${
                submitIsHousekeepingJob ? locations[0]?.shortDisplayName : ''
              }  ${endText}`
            : `#${id} ${getItems(submitItems)} ${
                locationsResponse[0]?.shortDisplayName
              } ${endText}`;

          globalSnack({
            message,
            timeout: 6000,
          });

          jobRefetch();

          if (onClose && typeof onClose === 'function') {
            onClose();

            setQuery({
              openJob: '',
            });
          }

          form.resetFields();
        }
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('assets:Something went wrong attempting to update job'),
          error: true,
          timeout: 5000,
        });
      }

      return null;
    },
    [
      JOB_TYPES.housekeeping.value,
      job,
      formatEditFields,
      globalSnack,
      t,
      updateJob,
      updateJobMetadata,
      ACTION_TYPE_OPTIONS,
      HOUSEKEEPING_ACTION_TYPE_OPTIONS,
      locations,
      jobRefetch,
      onClose,
      form,
      setQuery,
    ],
  );

  const handleEditTemplateSubmit = useCallback(
    async (_values: TJobData & TEditJobData, preventClosing?: boolean) => {
      const updateRepeatingJobInput = formatEditRepeatingFields(_values);

      try {
        await updateRepeatingJob({
          variables: {
            updateRepeatingJobInput,
          },
          refetchQueries: [
            {
              query: GET_REPEATING_JOB_SUMMARIES,
              variables: REFETCH_SUMMARY_VARIABLES,
              context: { _instance: 'node' },
            },
          ],
        });
        GoogleAnalyticsClient.event(GA_EVENTS.editJobModalUpdateButton);

        globalSnack({
          message: t(
            'jobs:The repeating job {{displayName}} has been updated.',
            {
              displayName: job.displayName,
            },
          ),
          timeout: 5000,
        });

        if (!preventClosing && onClose && typeof onClose === 'function') {
          onClose();
        }

        if (preventClosing) {
          repeatingJobRefetch();
        }

        form.resetFields();
      } catch (error) {
        console.error(error);
        globalSnack({
          message: t(
            'jobs:An error has occurred while updating this repeating job',
          ),
          timeout: 5000,
          error: true,
        });
      }
    },
    [
      formatEditRepeatingFields,
      updateRepeatingJob,
      globalSnack,
      t,
      job,
      onClose,
      form,
      repeatingJobRefetch,
    ],
  );

  const handleSubmit = useCallback(
    async (_values: TJobData & TEditJobData) => {
      if (mode === 'editTemplate' && !showEditConfirmation) {
        setShowEditConfirmation(true);

        return;
      }

      if (mode === 'editTemplate' && showEditConfirmation) {
        const previousNotes =
          job?.jobTemplate?.notes?.map(
            (noteItem: { id: string; text: string }) => ({
              id: noteItem.id,
              note: noteItem.text,
            }),
          ) || [];

        await handleEditTemplateSubmit({
          ...watchedValues,
          notes: [...previousNotes],
        });

        return;
      }

      if (mode === 'edit') {
        await handleEditSubmit(_values as TEditJobData);

        return;
      }

      await handleCreateSubmit(_values as TJobData);
    },
    [
      handleCreateSubmit,
      handleEditSubmit,
      handleEditTemplateSubmit,
      job,
      mode,
      showEditConfirmation,
      watchedValues,
    ],
  );

  const handleAddNote = useCallback(
    async (_values: THandleAddNote) => {
      const seletedStatus =
        watchedValues?.status === JOB_STATUSES.notStarted.value
          ? JOB_ADVANCED_STATUSES.new?.value
          : watchedValues?.status;

      try {
        await updateJob({
          variables: {
            id: _values.id,
            input: {
              action: watchedValues?.actionType,
              status: seletedStatus,
              notes: [
                {
                  text: _values.notes,
                },
              ],
            },
          },
        });

        _values?.setNotes('');

        jobRefetch();

        GoogleAnalyticsClient.event(GA_EVENTS.addNoteJob);
      } catch (err) {
        console.error("Couldn't add note!", err);

        globalSnack({
          message: t('jobs:There was a problem adding your note'),
          timeout: 5000,
          error: true,
        });
      }
    },
    [globalSnack, jobRefetch, t, updateJob, watchedValues],
  );

  const handleAddTemplateNote = useCallback(
    async (_values: THandleAddTemplateNote) => {
      const previousNotes =
        job?.jobTemplate?.notes?.map(
          (noteItem: { id: string; text: string }) => ({
            id: noteItem.id,
            note: noteItem.text,
          }),
        ) || [];

      await handleEditTemplateSubmit(
        {
          ...watchedValues,
          notes: [
            ...previousNotes,
            {
              note: _values.notes,
            },
          ],
        },
        true,
      );

      _values.setNotes('');
    },
    [handleEditTemplateSubmit, job, watchedValues],
  );

  const getConstants = useCallback(
    () => ({
      JOB_TYPES,
      JOB_TYPE_OPTIONS,
      FORM_OPTIONS,
      ACTION_TYPE_OPTIONS,
      HOUSEKEEPING_ACTION_TYPE_OPTIONS,
    }),
    [
      ACTION_TYPE_OPTIONS,
      FORM_OPTIONS,
      HOUSEKEEPING_ACTION_TYPE_OPTIONS,
      JOB_TYPES,
      JOB_TYPE_OPTIONS,
    ],
  );

  const getInitialValues = useCallback(() => {
    const dueDate = job?.doBy ? dayjs.unix(job.doBy).tz(timezone) : TODAY_TIME;

    const dueTime = job?.doBy
      ? dayjs.unix(job.doBy).tz(timezone)
      : TODAY_TIME.add(
          jobsSettingsData && jobsSettingsData.jobsSettings.guestRequestMinutes,
          'minutes',
        );
    const scheduleStartTimeAt = job?.scheduleStartTimeAt
      ? dayjs.unix(job.scheduleStartTimeAt).tz(timezone)
      : TODAY_TIME;
    const startDate = TODAY_TIME;
    const jobType = job?.type;
    const actionType = job?.action;
    const creditValue = job?.creditValue;
    const durationMinutes = job?.durationMin;
    const rush = job?.isRushed;
    const priority = job?.priority;
    const role = job?.role?.id;
    const department = job?.department?.id;
    const assignee =
      job?.assignee?.id &&
      String(job?.assignee?.id) !== String(AUTO_ASSIGNEE_ID)
        ? String(job?.assignee?.id)
        : undefined;
    const items = job?.items?.map((item: TJobDataItem) => {
      const jobItem = jobItems.find(
        (_jobItem: { displayName: string }) =>
          _jobItem.displayName === item.name,
      );
      return {
        amount: item.amount,
        id: jobItem?.id,
      };
    });
    const assetId = job?.metadata?.publicAttributes?.asset?.id;
    const status = getStatusFromValue({
      value: job?.status || '',
    });
    const timeWindowStart = job?.timeWindowStart
      ? dayjs.unix(job?.timeWindowStart)
      : '';
    const timeWindowEnd = job?.timeWindowEnd
      ? dayjs.unix(job.timeWindowEnd)
      : '';
    const location = job?.locations?.map(
      (jobLocation: { id: string }) => jobLocation.id,
    );
    const attachments = job?.attachments?.map((attachment: string) => ({
      uid: attachment,
    }));
    const checklistTemplates =
      (job?.checklistTemplate?.length &&
        job?.checklistTemplate?.map(
          (checklistTemplate: { id: string }) => checklistTemplate.id,
        )) ||
      null;

    const templateAssetId =
      job?.jobTemplate?.metadata?.publicAttributes?.asset?.id;
    const templateItems = job?.jobTemplate?.items?.map((item: TJobDataItem) => {
      const jobItem = jobItems.find(
        (_jobItem: TJobDataItem) => _jobItem.displayName === item.name,
      );

      return {
        amount: item.amount,
        id: jobItem?.id,
      };
    });
    const templateLocation = job?.jobTemplate?.locations?.map(
      (_location: { id: string }) => _location.id,
    );
    const [templateDueTimeHour, templateDueTimeMinute, templateDueTimeSecond] =
      job?.jobTemplate?.dueByTime?.split(':') || [0, 0, 0];
    const [
      templateStartTimeHour,
      templateStartTimeMinute,
      templateStartTimeSecond,
    ] = job?.jobTemplate?.scheduleStartTimeAt?.split(':') || [0, 0, 0];
    const templateFrequency = String(job?.frequency).toLowerCase();
    const templateStatus =
      job?.jobTemplate?.status === JOB_ADVANCED_STATUSES.new?.value
        ? JOB_STATUSES.notStarted.value
        : job?.jobTemplate?.status;
    const templateStartDate =
      job?.startDate &&
      dayjs(String(job.startDate).substring(0, 19))
        .startOf('day')
        .tz(timezone, true);
    const templateEndDate =
      job?.endDate &&
      dayjs(String(job.endDate).substring(0, 19))
        .startOf('day')
        .tz(timezone, true);

    const templateCycle = {
      multipleTimesPerDay: false,
      timesPerDay: null,
      interval: null,
      firstHour: null,
      weekDay: [],
      dayOfMonth: 0,
    };

    const templateCycleDecoder = {
      [JOB_REPEAT.daily.value]: getDecodeDailyCadenceFromText,
      [JOB_REPEAT.weekly.value]: getDecodeWeeklyCadenceFromText,
      [JOB_REPEAT.monthly.value]: getDecodeMonthlyCadenceFromText,
      [JOB_REPEAT.yearly.value]: getDecodeYearlyCadenceFromText,
    };

    if (templateCycleDecoder[templateFrequency] && job?.cycle) {
      Object.assign(templateCycle, {
        ...templateCycleDecoder[templateFrequency](job.cycle?.value),
      });
    }

    const templateDueTime = job?.jobTemplate?.dueByTime
      ? dayjs()
          .tz('GMT')
          .startOf('day')
          .add(templateDueTimeHour, 'hours')
          .add(templateDueTimeMinute, 'minutes')
          .add(templateDueTimeSecond, 'seconds')
          .tz(timezone)
      : null;

    const templateStartTime = job?.jobTemplate?.scheduleStartTimeAt
      ? dayjs()
          .tz('GMT')
          .startOf('day')
          .add(templateStartTimeHour, 'hours')
          .add(templateStartTimeMinute, 'minutes')
          .add(templateStartTimeSecond, 'seconds')
          .tz(timezone)
      : null;

    const templateNotes =
      job?.jobTemplate?.notes?.length && job.jobTemplate?.notes[0]?.text;
    const templateAttachments = job?.jobTemplate?.attachments?.map(
      (attachment: string) => ({
        uid: attachment,
      }),
    );
    const templateChecklists =
      (job?.jobTemplate?.checklists &&
        job.jobTemplate?.checklists[0] &&
        job.jobTemplate?.checklists[0]?.id) ||
      [];

    const templateInitialValues = job?.jobTemplate
      ? {
          jobType: job.jobTemplate.type,
          actionType: job.jobTemplate.action,
          items: templateItems,
          location: templateLocation,
          asset: templateAssetId,
          status: templateStatus,
          priority: job.jobTemplate.priority,
          repeat: templateFrequency,
          startDate: templateStartDate,
          endDate: templateEndDate,
          dueTime: templateDueTimeHour ? templateDueTime : null,
          scheduleStartTimeAt: templateStartTimeHour ? templateStartTime : null,
          firstDueTime: templateDueTime,

          dailyDueTime:
            !templateCycle.multipleTimesPerDay && templateDueTime
              ? templateDueTime
              : null,

          weeklyDueTime: templateDueTime,
          monthlyDueTime: templateDueTime,
          yearlyDueTime: templateDueTime,
          multiple: templateCycle.multipleTimesPerDay,
          timesPerDay: String(templateCycle?.timesPerDay),
          repeatHour: String(templateCycle?.interval),
          repeatWeeks: String(templateCycle?.interval),
          daysOfWeek: templateCycle?.weekDay,
          repeatMonths: String(templateCycle?.interval),
          repeatYears: String(templateCycle?.interval),
          dayOfMonth: String(templateCycle?.dayOfMonth),
          department: job.jobTemplate.department?.id,
          assignee: job.jobTemplate.assignee?.id,
          role: job.jobTemplate.role?.id,
          checklist: templateChecklists,
          notes: templateNotes,
          attachments: templateAttachments,
          timezone,
        }
      : {};

    const preInitialValues =
      mode === 'editTemplate'
        ? templateInitialValues
        : {
            jobType: JOB_TYPES.guest.value,
            items: [
              {
                id: null,
                amount: 1,
              },
            ] as TJobDataItem[],
            status: JOB_STATUSES.notStarted.value,
            priority: JOB_PRIORITY_LEVELS.high.value,
            repeat: JOB_REPEAT.no.value,
            assignee: canAutoAssign ? String(AUTO_ASSIGNEE.id) : '',
            dueDate,
            dueTime,
            dailyDueTime: dueTime,
            weeklyDueTime: dueTime,
            monthlyDueTime: dueTime,
            yearlyDueTime: dueTime,
            scheduleStartTimeAt,
            startDate,
            timesPerDay: '1',
            timezone,
          };

    const initialValues =
      mode === 'edit'
        ? {
            jobType,
            actionType,
            items,
            asset: assetId,
            location,
            status,
            priority,
            dueDate,
            dueTime,
            scheduleStartTimeAt,
            department,
            role,
            assignee,
            timezone,
            creditValue,
            durationMinutes,
            rush,
            timeWindowStart,
            timeWindowEnd,
            attachments,
            checklist: checklistTemplates,
          }
        : preInitialValues;

    return initialValues;
  }, [
    JOB_TYPES.guest.value,
    TODAY_TIME,
    canAutoAssign,
    job,
    jobItems,
    jobsSettingsData,
    mode,
    timezone,
  ]);

  useEffect(() => {
    if (queuedJobRequest) {
      getQueuedJobById();
    }
  }, [queuedJobRequest, query.openJob, getQueuedJobById]);

  return {
    locations,
    departments,
    roles,
    employees,
    jobItems,
    locationsAssetType,
    asset,
    assets,
    audit,
    job,
    loading,
    createLoading,
    editLoading,
    updateLoading,
    updateRepeatingJobLoading,
    loadingMinimalLocations,
    submitted,
    toggleSubmitted,
    updateJob,
    updateRepeatingJob,
    jobRefetch,
    repeatingJobRefetch,
    getConstants,
    getInitialValues,
    handleSubmit,
    updateChecklistTask,
    showEditConfirmation,
    setShowEditConfirmation,
    handleAddNote,
    handleAddTemplateNote,
    getRoomsData,
    roomStatus,
    propertyShard,
  };
}
