import { SendOutlined } from '@ant-design/icons';
import {
  Button,
  COLORS,
  ConfigProvider,
  Flex,
  RADIUS,
  SPACING,
  ThemeConfig,
  Typography,
} from '@optii/ui-library';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Conversation,
  JSONValue,
  Message,
  Paginator,
} from '@twilio/conversations';
import {
  PERMISSIONS,
  useAccess,
  UserAccessContext,
  GoogleAnalyticsClient,
  GA_EVENTS,
  Session,
} from '@optii/shared';
import { ChatContext } from '../../context/chat.context';
import { CHANNEL_TYPES } from '../../constants';
import { FileList, TaggedUser } from '../../types';
import { UploadFile } from './UploadFIle';
import { InputFilePreview } from './InputFilePreview';
import {
  generateAttributes,
  OPTII_AUTHOR_ID,
  sendMessage,
} from '../../utils/message';
import MessageField from './MessageField';
import { useSuggestionMutation } from '../../api/chat';
import { EmployeeStatus } from '../../api/data';

type MessageInputProps = {
  channel: Conversation | undefined;
  setDataSource: Dispatch<SetStateAction<Paginator<Message> | undefined>>;
  name: string | null | undefined;
};

const PRIVATE_CHANNEL_PREFIX = 'ªº'; // Disables Mentions for private channels

export function MessageInput({
  channel,
  setDataSource,
  name,
}: MessageInputProps) {
  const { t } = useTranslation(['chat', 'common']);
  const { globalSnack } = useContext(Session);

  const isPrivateChannel =
    (channel?.attributes as { ChannelType: string })?.ChannelType !==
    CHANNEL_TYPES.public;
  const { user } = useContext(UserAccessContext.Context);
  const { can } = useAccess();
  const { employees } = useContext(ChatContext);
  const [value, setValue] = useState<string | undefined>('');
  const [taggedUsers, setTaggedUsers] = useState<Array<TaggedUser>>([]);
  const [fileList, setFileList] = useState<FileList[]>([]);
  const [status, setStatus] = useState<'error' | ''>('');

  const CAN_SEE_SUGGESTIONS = can(PERMISSIONS.communications.chatAssist);

  const OPTII_BOT_MESSAGE_TEMPLATE = {
    author: OPTII_AUTHOR_ID,
    sid: '-99',
    body: t('chat:Hi [[{{user}}]], is this a Job?', {
      user: `${user?.id}@${user?.firstName} ${user?.lastName}`,
    }),
    attributes: {
      sender: {
        id: user?.id || '',
      },
    },
    dateCreated: new Date(),
  };

  const [generateSuggestion] = useSuggestionMutation({
    onError(error) {
      console.error(error);
    },
  });

  const handleChannelAnalytics = () => {
    if (isPrivateChannel) {
      GoogleAnalyticsClient.event(GA_EVENTS.sendMessageDirect);
    } else if (channel?.friendlyName === 'All Team Members') {
      GoogleAnalyticsClient.event(GA_EVENTS.sendMessageAllMembers);
    } else GoogleAnalyticsClient.event(GA_EVENTS.sendMessageDepartment);
  };

  function filterTaggedUsers(input: string) {
    setTaggedUsers((prev) =>
      prev.filter((label) => {
        const userLabelMention = `@${label}`;

        return input.includes(userLabelMention);
      }),
    );
  }

  const THEME: ThemeConfig = {
    components: {
      Input: {
        borderRadiusLG: RADIUS.RADIUS_L,
        sizeXL: 40,
      },
      Mentions: {
        paddingInline: 16,
        paddingBlock: 12,
      },
      Button: {
        colorPrimary: COLORS.secondary[5],
        colorPrimaryActive: COLORS.secondary[6],
        colorPrimaryHover: COLORS.secondary[6],
      },
    },
  };

  const onSendMessage = async () => {
    const isInputEmpty = !value && !fileList.length;

    if (isInputEmpty || !channel) return;

    if (value) {
      filterTaggedUsers(value);
    }

    const attributes = generateAttributes(
      fileList,
      isPrivateChannel,
      channel,
      user,
    ) as JSONValue;
    handleChannelAnalytics();

    setFileList([]);

    await sendMessage(
      setValue,
      taggedUsers,
      t,
      attributes,
      channel,
      value || ' ',
      setDataSource,
    )
      .then(async () => {
        if (CAN_SEE_SUGGESTIONS) {
          await generateSuggestion({
            variables: {
              jobCheck: true,
              text: value,
            },
            onCompleted({ suggestion }) {
              if (suggestion.isJob) {
                setDataSource((prev) =>
                  prev
                    ? {
                        ...prev,
                        items: prev.items.concat([
                          {
                            ...OPTII_BOT_MESSAGE_TEMPLATE,
                            index: prev.items.length + 1,
                            attributes: {
                              ...OPTII_BOT_MESSAGE_TEMPLATE.attributes,
                              suggestionText: value,
                            },
                          } as unknown as Message,
                        ]),
                      }
                    : prev,
                );
              }
            },
          });
        }
        setStatus('');
      })
      .catch((err) => {
        globalSnack({
          message: 'Error sending message',
          timeout: 6000,
          error: true,
        });

        setStatus('error');
        console.error(err);
      });
  };

  const mentionOptions =
    useMemo(
      () =>
        [
          {
            value: t('chat:Channel'),
            label: t('chat:Channel'),
            key: 'channel',
          },
        ].concat(
          employees
            .filter((employee) => employee.value !== user?.id)
            .filter((employee) => employee.status === EmployeeStatus.Active)
            .map((employee) => ({
              value: employee.label,
              label: employee.label,
              key: employee.value,
            })),
        ),
      [employees, t, user?.id],
    ) || [];

  return (
    <ConfigProvider theme={THEME}>
      <InputFilePreview fileList={fileList} setFileList={setFileList} />
      {status === 'error' ? (
        <Flex justify="space-between" align="center">
          <Typography.Text
            type="danger"
            style={{
              display: 'flex',
              marginLeft: 48,
            }}
          >
            Shorten message and Try again
          </Typography.Text>
        </Flex>
      ) : null}
      <Flex
        gap={SPACING.SIZE_DEFAULT}
        style={{
          position: 'relative',
        }}
        align="center"
      >
        <UploadFile setFileList={setFileList} />

        <MessageField
          placeholder={t('chat:Message {{channelName}}', {
            channelName: name,
          })}
          options={mentionOptions}
          value={value}
          key="key"
          prefix={isPrivateChannel ? PRIVATE_CHANNEL_PREFIX : '@'}
          styles={{
            textarea: {
              minHeight: 48,
              paddingRight: 48,
            },
          }}
          setStatus={setStatus}
          status={status}
          autoSize={{
            maxRows: 6,
          }}
          onSelect={(option) => taggedUsers.push(option as TaggedUser)}
          setTaggedUsers={setTaggedUsers}
          setValue={setValue}
          onSendMessage={onSendMessage}
          channel={channel}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={onSendMessage}
          style={{
            position: 'absolute',
            right: 10,
            top: 0,
            transform: 'translate(-8px, 8px)',
          }}
        />
      </Flex>
    </ConfigProvider>
  );
}
