import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  LeftOutlined,
  Loading3QuartersOutlined,
  MoreOutlined,
} from '@ant-design/icons';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import JobDetails from '@optii/topcat-client/components/Jobs/JobDetails';
import { JobForm } from '@optii/jobs/index';
import {
  Conversation,
  JSONValue,
  Message,
  Paginator,
  Participant,
  ParticipantUpdateReason,
} from '@twilio/conversations';
import dayjs, { Dayjs } from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
// import VirtualList from 'rc-virtual-list'; In case we require virtualization, this would be the alternative to the InfiniteScroll
import {
  PERMISSIONS,
  Session,
  useAccess,
  usePropertyTime,
  UserAccessContext,
} from '@optii/shared/index';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  Dropdown,
  Button,
  Card,
  COLORS,
  ConfigProvider,
  Divider,
  Flex,
  FONTS,
  Grid,
  List,
  RADIUS,
  Skeleton,
  SPACING,
  Spin,
  ThemeConfig,
  Typography,
  MenuProps,
  App,
  Tooltip,
} from '@optii/ui-library';
import { EmployeeStatus } from '@optii/global';
import { isPlatform } from '@ionic/react';
import { MessageInput } from './MessageInput';
import { ChatContext } from '../../context/chat.context';
import { MessageItem } from './Message';
import { SelectOption } from './types';
import {
  formatBody,
  generateAttributes,
  OPTII_AUTHOR_ID,
} from '../../utils/message';
import { CHANNEL_TYPES, PRIVATE_CHAT_REGEX } from '../../constants';
import {
  SuggestionMutation,
  SuggestionMutationFn,
  useSuggestionMutation,
} from '../../api/chat';
import { ChannelActions } from './ChannelActions';
import { ConversationActions } from '../../types';

type CustomValues = {
  action: string;
  jobItemId: string;
  item: string;
  amount: string;
  location: string;
  department: string;
  role: string;
  jobType: string;
};

const LIST_STYLE: CSSProperties = {
  maxHeight: '69vh',
  minHeight: '500px',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginBottom: SPACING.SIZE_MD,
  overflowAnchor: 'none',
  flexDirection: 'column-reverse',
};

const THEME: ThemeConfig = {
  components: {
    List: {
      itemPadding: String(SPACING.NONE),
    },
    Card: {
      paddingLG: SPACING.SIZE_MD,
    },
  },
};

const CARD_TITLE_THEME: ThemeConfig = {
  token: {
    fontFamily: 'Roboto',
    colorText: COLORS.neutral[9],
    fontSize: FONTS.large.size,
  },
};

type RenderItemProps = {
  item: Message;
  employees: SelectOption[];
  index: number;
  array?: Message[];
  participants: Participant[] | undefined;
  generateJob: SuggestionMutationFn;
  jobLoading: boolean;
  timezone?: string;
};

function DateSeparator({ date }: { date: Dayjs }) {
  const DATE_THEME: ThemeConfig = {
    components: {
      Divider: {
        textPaddingInline: SPACING.NONE,
        margin: SPACING.NONE,
      },
      Typography: {
        fontSize: FONTS.xSmall.size,
        colorText: COLORS.neutral[9],
      },
    },
  };
  return (
    <ConfigProvider theme={DATE_THEME}>
      <Divider
        plain
        style={{
          marginBottom: SPACING.SIZE_DEFAULT,
        }}
      >
        <Flex
          style={{
            border: `1px solid ${COLORS.neutral[5]}`,
            background: COLORS.neutral[1],
            borderRadius: RADIUS.RADIUS_XL,
            padding: `${SPACING.SIZE_XS}px ${SPACING.SIZE_MS}px`,
          }}
        >
          <Typography.Text style={{ fontWeight: 500 }}>
            {date.format('LL')}
          </Typography.Text>
        </Flex>
      </Divider>
    </ConfigProvider>
  );
}

function RenderItem({
  item,
  employees,
  index,
  array,
  participants,
  generateJob,
  jobLoading,
  timezone,
}: RenderItemProps) {
  const { dateCreated, author } = item;
  const timestamp = dayjs(dateCreated).tz(timezone);
  const todayTimestamp =
    array && dayjs(array[index - 1]?.dateCreated).format('LL');

  const authorName =
    author !== OPTII_AUTHOR_ID
      ? employees.find(({ value }) => value === author)?.label
      : 'Optii';
  return authorName && author ? (
    <Fragment key={item.sid}>
      {index === 0 || timestamp.format('LL') !== todayTimestamp ? (
        <DateSeparator date={timestamp} />
      ) : null}
      <List.Item key={item.sid}>
        <MessageItem
          key={item.sid}
          message={item}
          generateJob={generateJob}
          jobLoading={jobLoading}
          authorName={authorName}
          participants={participants}
          timestamp={timestamp}
          isOptii={author === OPTII_AUTHOR_ID}
        />
      </List.Item>
    </Fragment>
  ) : null;
}

export function MessageList() {
  const {
    employees,
    getChannelById,
    setChannels,
    generalChannelId,
    setUnreadConversations,
  } = useContext(ChatContext);
  const { t } = useTranslation(['chat', 'common']);
  const { id } = useParams<{ id: string }>();
  const { user } = useContext(UserAccessContext.Context);
  const { can } = useAccess();
  const { timezone } = usePropertyTime(null);
  const isIOS = isPlatform('ios');

  const [dataSource, setDataSource] = useState<Paginator<Message>>();
  const [channel, setChannel] = useState<Conversation | undefined>(undefined);
  const [participants, setParticipants] = useState<Participant[] | undefined>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [customValues, setCustomValues] = useState<
    SuggestionMutation['suggestion']
  >({});
  const [openJob, setOpenJob] = useState(false);
  const [action, setAction] = useState<ConversationActions | undefined>(
    undefined,
  );

  const { globalSnack } = useContext(Session);

  const [viewJob, setViewJob] = useQueryParam('openJob', StringParam);

  const { useBreakpoint } = Grid;
  const { modal } = App.useApp();
  const { xs } = useBreakpoint();

  const history = useHistory();

  const isChannelOwner = channel?.createdBy === user?.id;
  const isPrivateGroupChannel = channel?.uniqueName
    ? channel.uniqueName.split('_').length > 2
    : false;

  const isPrivateChannel = channel?.uniqueName
    ? channel.uniqueName.split('_').length === 2
    : false;

  const canAccessDynamicGroups = can(PERMISSIONS.communications.dynamicGroups);

  const inactiveRecipient = useMemo(() => {
    if (
      !(channel?.attributes as { ChannelType: 'Public' })?.ChannelType &&
      !isPrivateGroupChannel
    )
      return employees.find(
        (item) =>
          channel?.uniqueName
            ?.split('_')
            .filter((employeeId) => employeeId !== user?.id)
            .includes(item.value) && item.status !== EmployeeStatus.Active,
      );

    return undefined;
  }, [channel, employees, user?.id, isPrivateGroupChannel]);

  const [generateJob, { loading: jobLoading }] = useSuggestionMutation({
    onCompleted({ suggestion }) {
      if (suggestion.isJob === null) {
        setCustomValues(suggestion);
        setOpenJob(true);
      }
    },
    onError(error) {
      console.error(error);
      globalSnack({
        message: t('chat:An error occurred while generating a suggestion'),
        error: true,
        timeout: 5000,
      });
    },
  });

  async function onLoadMoreData() {
    if (!dataSource?.hasPrevPage && !dataSource?.items) return [];

    const nextPage = await dataSource.prevPage();

    setDataSource((prev) => ({
      ...nextPage,
      items: [...nextPage.items, ...(prev?.items || [])],
    }));

    return [...nextPage.items, ...dataSource.items];
  }

  const getChannel = useCallback(async () => {
    setLoading(true);
    setAction(undefined);

    try {
      const channelData = await getChannelById(id);

      await channelData?.setAllMessagesRead();

      if (channelData?.sid === generalChannelId && channelData) {
        setUnreadConversations(({ channels: unreadChannels, total }) => ({
          total: total - unreadChannels[channelData.sid],
          channels: {
            ...unreadChannels,
            [channelData.sid]: 0,
          },
        }));
      }

      setParticipants(await channelData?.getParticipants());

      setChannel(channelData);

      const messages = await channelData?.getMessages(30).finally(() => {
        setLoading(false);
      });

      if (!messages) {
        console.error(
          'Something went wrong when getting messages for this channel.',
        );
      } else setDataSource(messages);
    } catch (error) {
      console.error(error);
      if (generalChannelId) history.push(generalChannelId);
    }
  }, [getChannelById, id, history, generalChannelId, setUnreadConversations]);

  const onJobCreated = useCallback(
    async (jobId?: string) => {
      if (typeof jobId === 'string' && channel) {
        const builder = channel.prepareMessage();

        const isChannelPrivate =
          (channel?.attributes as { ChannelType: string })?.ChannelType !==
          CHANNEL_TYPES.public;
        builder.setBody(formatBody(`Added Job #${jobId}`, [], t));

        builder.setAttributes(
          generateAttributes([], isChannelPrivate, channel, user) as JSONValue,
        );
        setDataSource((prev) => {
          if (prev) {
            const updatedMessages = prev?.items.map((item, index) => {
              if (item.sid === '-99') {
                return {
                  ...item,
                  sid: `-${index}`,
                } as Message;
              }
              return item;
            });

            return {
              ...prev,
              items: updatedMessages,
            };
          }

          return prev;
        });
        await builder.buildAndSend();
      }
    },
    [channel, t, user],
  );

  useEffect(() => {
    getChannel();
  }, [getChannel, id]);

  const onMessageAdded = useCallback(
    async (message: Message) => {
      if (channel && user)
        if (id === message.conversation.sid) {
          if (user.id.toString() !== message.author?.toString()) {
            if (document.visibilityState === 'visible')
              channel.advanceLastReadMessageIndex(message.index);

            setDataSource((prev) => {
              if (prev) {
                return {
                  ...prev,
                  items: prev.items.concat([message]),
                };
              }
              return prev;
            });
          }

          if (message.author?.toString() === user.id.toString()) {
            // Remove optimistic  message in favor of actual message
            setDataSource((prev) => {
              if (prev) {
                const filteredMessages = prev?.items.map((item) => {
                  if (
                    message.author === item.author &&
                    message.body === item.body &&
                    (item.index === -1 || item.index === message.index)
                  )
                    return message;

                  return item;
                });
                // If no optimistic message is present, simply concat the new message to the list.
                if (
                  !filteredMessages.some(
                    (filteredMessage) =>
                      filteredMessage.index === message.index ||
                      filteredMessage.sid === message.sid,
                  )
                ) {
                  filteredMessages.push(message);
                }

                return {
                  ...prev,
                  items: filteredMessages,
                };
              }
              return prev;
            });
          }
        }
    },
    [channel, id, user],
  );

  const onTabFocus = useCallback(() => {
    if (dataSource?.items?.[dataSource.items.length - 1]?.index) {
      channel?.advanceLastReadMessageIndex(
        dataSource.items[dataSource.items.length - 1].index,
      );
    }
  }, [dataSource, channel]);

  useEffect(() => {
    window.addEventListener('focus', onTabFocus);

    return () => {
      window.removeEventListener('focus', onTabFocus);
    };
  }, [channel, dataSource, onTabFocus]);

  useEffect(() => {
    if (channel && user) {
      channel.on('messageAdded', onMessageAdded);
    }

    return () => {
      if (channel) {
        channel.removeListener('messageAdded', onMessageAdded);
      }
    };
  }, [id, channel, user, onMessageAdded]);

  const onParticipantUpdated = useCallback(
    ({
      participant,
      updateReasons,
    }: {
      participant: Participant;
      updateReasons: ParticipantUpdateReason[];
    }) => {
      const reason = updateReasons.includes('lastReadMessageIndex');

      if (reason) {
        setParticipants((prev) =>
          prev?.map((outdatedParticipant) => {
            if (participant.identity === outdatedParticipant.identity)
              return participant;

            return outdatedParticipant;
          }),
        );
      }
    },
    [],
  );

  useEffect(() => {
    if (participants) {
      participants.map((participant) =>
        participant.on('updated', onParticipantUpdated),
      );
    }
    return () => {
      participants?.map((item) =>
        item.removeListener('updated', onParticipantUpdated),
      );
    };
  }, [participants, onParticipantUpdated]);

  function getChannelName(max?: number, displayEmployees = false) {
    const channelType = (channel?.attributes as { ChannelType: 'Public' })
      ?.ChannelType;

    if (channelType) return channel?.friendlyName;

    if (displayEmployees) {
      const employeeNames = channel?.uniqueName
        ?.split('_')
        .filter((item) => item !== user?.id)
        .map(
          (recipientId) =>
            employees?.find((employee) => employee.value === recipientId)
              ?.label,
        )
        .join(', ');

      return channel?.uniqueName === channel?.friendlyName ? '' : employeeNames;
    }

    if (channel?.friendlyName?.match(PRIVATE_CHAT_REGEX)) {
      const customName = channel.friendlyName
        .split('_')
        .filter((item) => item !== user?.id)
        .map(
          (recipientId) =>
            employees.find((employee) => employee.value === recipientId)?.label,
        )
        .sort((a, b) => {
          if (a && b) {
            return a.localeCompare(b);
          }
          return 0;
        });

      if (max) {
        return `${customName.slice(0, max).join(', ')}${customName.length > max ? '...' : ''}`;
      }
      return customName.join(', ');
    }
    return channel?.friendlyName;
  }

  const items: MenuProps['items'] = [
    canAccessDynamicGroups && isPrivateGroupChannel
      ? {
          key: '1',
          label: (
            <Typography.Text
              style={{
                letterSpacing: FONTS.medium.letterSpacing,
              }}
            >
              {t('common:Edit')}
            </Typography.Text>
          ),
          onClick() {
            setAction(ConversationActions.Edit);
          },
        }
      : null,
    (isPrivateGroupChannel && canAccessDynamicGroups) || isPrivateChannel
      ? {
          key: '2',
          label: (
            <Typography.Text
              style={{
                letterSpacing: FONTS.medium.letterSpacing,
              }}
            >
              {t('common:Delete')}
            </Typography.Text>
          ),
          onClick() {
            setAction(ConversationActions.Delete);
            modal.confirm({
              title: t('chat:Delete Direct Message'),
              icon: null,
              content: t(
                'chat:Deleting this direct message permanently removes all of your chat history.',
              ),
              onOk() {
                channel?.delete().finally(() => {
                  setChannels((prev) => ({
                    publicChannels: prev.publicChannels,
                    privateChannels: prev.privateChannels.filter(
                      (item) => item.key !== channel.sid,
                    ),
                  }));
                  history.push('/messages');
                });
              },
              centered: true,
              cancelText: t('common:Cancel'),
              onCancel() {
                setAction(undefined);
              },
              okText: t('common:Yes, delete'),
            });
          },
        }
      : null,
    isChannelOwner && isPrivateGroupChannel
      ? {
          key: '3',
          label: (
            <Typography.Text
              style={{
                letterSpacing: FONTS.medium.letterSpacing,
              }}
            >
              {t('common:Rename')}
            </Typography.Text>
          ),
          onClick() {
            setAction(ConversationActions.Rename);
          },
        }
      : null,
  ].filter((item) => item);

  return (
    <>
      <ConfigProvider theme={THEME}>
        <Card
          style={{
            marginRight: SPACING.SIZE_MD,
            marginBottom: SPACING.SIZE_MD,
          }}
          title={
            <ConfigProvider theme={CARD_TITLE_THEME}>
              <Flex align="center" gap={SPACING.SIZE_MS}>
                {xs ? (
                  <Button
                    icon={<LeftOutlined />}
                    ghost
                    onClick={() => history.push('/messages')}
                    type="text"
                  />
                ) : null}

                <Flex
                  align="center"
                  justify="space-between"
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Skeleton
                    loading={loading}
                    active
                    title={false}
                    paragraph={{
                      rows: 1,
                      style: {
                        fontSize: FONTS.large.size,
                        lineHeight: FONTS.large.lineHeight,
                        width: 300,
                      },
                    }}
                  >
                    {action && action !== ConversationActions.Delete ? (
                      <ChannelActions
                        action={action}
                        channel={channel}
                        setAction={setAction}
                      />
                    ) : (
                      <Tooltip title={getChannelName(undefined, true)}>
                        <Typography.Paragraph
                          style={{
                            fontWeight: 500,
                            margin: SPACING.NONE,
                            marginTop: isIOS ? SPACING.SIZE_XS : SPACING.NONE,
                          }}
                          ellipsis={{
                            tooltip: getChannelName(),
                          }}
                        >
                          {getChannelName()}
                        </Typography.Paragraph>
                      </Tooltip>
                    )}
                  </Skeleton>

                  {((!(channel?.attributes as { ChannelType: 'Public' })
                    ?.ChannelType &&
                    !action) ||
                    action !== ConversationActions.Delete) &&
                  items.length ? (
                    <ConfigProvider
                      theme={{
                        components: {
                          Typography: {
                            colorText: COLORS.neutral[8],
                            fontSize: FONTS.medium.size,
                          },
                          Dropdown: {
                            paddingBlock: SPACING.SIZE_SM,
                          },
                        },
                      }}
                    >
                      <Dropdown
                        menu={{
                          items,
                        }}
                        overlayStyle={{
                          width: 206,
                        }}
                        trigger={['click']}
                      >
                        <Button type="text" icon={<MoreOutlined />} />
                      </Dropdown>
                    </ConfigProvider>
                  ) : null}
                </Flex>
              </Flex>
            </ConfigProvider>
          }
        >
          <Flex vertical style={LIST_STYLE} id="scroll">
            {loading ? (
              <Spin indicator={<Loading3QuartersOutlined spin />} />
            ) : (
              <InfiniteScroll
                next={onLoadMoreData}
                hasMore={dataSource?.hasPrevPage || false}
                key="sid"
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                dataLength={dataSource?.items.length || 0}
                scrollableTarget="scroll"
                pullDownToRefreshThreshold={100}
                initialScrollY={700}
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
                inverse
              >
                <List
                  style={{
                    overflow: 'hidden',
                  }}
                  locale={{
                    emptyText: 'No messages yet, please add a message.',
                  }}
                  itemLayout="horizontal"
                  dataSource={dataSource?.items}
                  rowKey="sid"
                  renderItem={(item, index) =>
                    RenderItem({
                      item,
                      employees,
                      index,
                      array: dataSource?.items,
                      participants,
                      generateJob,
                      jobLoading,
                      timezone,
                    })
                  }
                  split={false}
                  id="list"
                />
              </InfiniteScroll>
            )}
          </Flex>
          {inactiveRecipient ? (
            <>
              <Divider
                style={{
                  marginBottom: SPACING.SIZE_SM,
                }}
              />
              <Typography.Text italic>
                {t('chat:{{userFullName}} is no longer an active user', {
                  userFullName: inactiveRecipient.label,
                })}
              </Typography.Text>
            </>
          ) : (
            <MessageInput
              channel={channel}
              name={getChannelName(4)}
              setDataSource={setDataSource}
            />
          )}
        </Card>
      </ConfigProvider>

      <JobForm
        open={openJob}
        mode="add"
        customValues={customValues as CustomValues}
        onClose={async (jobId) => {
          setOpenJob(false);
          if (typeof jobId === 'string') onJobCreated(jobId);
        }}
      />
      {viewJob ? (
        <JobDetails
          onClose={() => setViewJob(undefined)}
          legacyLogs={[]}
          open={!!viewJob}
        />
      ) : null}
    </>
  );
}
